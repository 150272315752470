import React from "react"
import Wischer from "../components/wischer"

import Seo from "../components/Seo"
const Wischerseite = () => {
  return (
    <>
      <Wischer />
    </>
  )
}

export default Wischerseite